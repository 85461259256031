.InovuaReactDataGrid__row {
  background-color: #f2ede1 !important;
}

.InovuaReactDataGrid  {
  border-radius: 10px;
  border: None;
  background-color: #f2ede1 !important;
}

.InovuaReactDataGrid__header {
  background-color: #f2ede1 !important;
  border-radius: 10px 10px 0 0;
}

.inovua-react-pagination-toolbar {
  background-color: #f2ede1 !important;
}